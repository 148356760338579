import React from "react";
// import PipelineItem from './PipelineItem';
// import PipelinesList from './PipelinesList';
import PipelineItem from "./PipelineItem";
import "./Pipelines.css";
import Card from "../Card";

const Pipelines = (props) => {
  return (
    <ul className="pipeline-item">
      {props.pipelines.map((pipeline) => (
        <PipelineItem
          name={pipeline.id}
          id={pipeline.iid}
          project_id={pipeline.project_id}
          web_url={pipeline.web_url}
        />
      ))}
    </ul>
  );
};

export default Pipelines;
