import React from "react";
import "./PipelineItem.css";
// import Card from "../Card";

const PipelineItem = (props) => {
  return (
    <li className="pipeline-item" id={props.id}>
      <h3>
        Pipeline{" "}
        <a href={props.web_url} target="_blank">
          {props.name}
        </a>
      </h3>
      <p>{props.id}</p>
    </li>
  );
};

export default PipelineItem;
